import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from '@themesberg/react-bootstrap';
import { useAuth } from "../components/AuthContext"; // Use the AuthContext
import { useNavigate } from "react-router-dom";
import RegisterModal from './Register';
import { toast } from "react-toastify";

export default function LoginModal({ show, handleClose }) {
  const { token, handleLogin } = useAuth(); // Use the AuthContext for token and login handling
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const handleShowRegisterModal = () => setShowRegisterModal(true);
  const handleCloseRegisterModal = () => setShowRegisterModal(false);

  // If token exists, close the modal
  useEffect(() => {
    if (token) {
      handleClose(); // Close the modal if the user is logged in
    }
  }, [token, handleClose]);

  // Handle user login
  const onLogin = async () => {
    setError(''); // Clear any previous errors
  
    const result = await handleLogin(email, password); // Call handleLogin from AuthContext
    if (result.success) {
      console.log('Token saved');
      setError('');
      toast.success('Login successful! Redirecting to settings...');
      navigate('/settings'); // Redirect to settings page after successful login
    } else {
      // Show error message from backend if login fails
      toast.error(result.message || 'Login failed. Please try again.');
      setError(result.message); // Set the error message to display on the UI
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Login to Your Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formPassword" className="mt-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          {error && <div className="text-danger mt-3">{error}</div>}

          {/* Buttons aligned on the left and right */}
          <div className="d-flex justify-content-between mt-4">
            <Button variant="primary" onClick={onLogin}>
              Login
            </Button>
            <Button variant="secondary" size="xs" onClick={handleShowRegisterModal} className="text-dark">
              Register
            </Button>
            <RegisterModal show={showRegisterModal} handleClose={handleCloseRegisterModal} />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
