
export const NavRoutes = {
    Presentation: { path: "/" },
    Dashboard: { path: "/dashboard" },
    Settings: { path: "/settings" },
    Admin: { path: "/admin" },
    VerifyEmail: { path: "/verify-email" },
    MemberList: { path: "/members" },
    NotFound: { path: "/404" },
    ServerError: { path: "/500" },
    Signin: { path: "/sign-in" },
    Signup: { path: "/sign-up" },
    ForgotPassword: { path: "/examples/forgot-password" },
    ResetPassword: { path: "/examples/reset-password" },
    Webhooks: { path: "/webhooks-tiktok" },
    TermsOfService: { path: "/TOS" },
    PrivacyPolicy: { path: "/PrivacyPolicy" },
};