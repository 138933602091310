import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container" style={{ margin: '20px' }}>
      <h2>Privacy Policy</h2>
      <div className="privacy-content">
        <p>
          Refactored Esports ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share information when you use our services. By using our services, you agree to the terms of this Privacy Policy.
        </p>

        <h3>1. Information We Collect</h3>
        <p>We collect only the minimal amount of information necessary to provide our services. Specifically, we collect:</p>
        <ul>
          <li><strong>Email Address:</strong> When you register for an account, we collect your email address for the purpose of account creation and communication.</li>
          <li><strong>Discord Account Information:</strong> If you link your Discord account, we may collect your Discord username and ID to enable integration features.</li>
          <li><strong>Epic Games Account Information:</strong> If you link your Epic Games account, we may collect your Epic Games ID and username to enable certain features within our platform.</li>
        </ul>

        <h3>2. How We Use Your Information</h3>
        <p>
          We use the information we collect for the following purposes:
        </p>
        <ul>
          <li>To create and manage your user account.</li>
          <li>To communicate with you regarding updates, announcements, and support.</li>
          <li>To provide integration with Discord and Epic Games services for authentication and participation in events or activities.</li>
        </ul>

        <h3>3. Sharing of Information</h3>
        <p>
          We do not sell, rent, or trade your personal information with third parties. We may share your information in the following limited circumstances:
        </p>
        <ul>
          <li><strong>With Service Providers:</strong> We may share your information with trusted third-party service providers who assist us in operating our services, such as hosting platforms or email providers.</li>
          <li><strong>Legal Requirements:</strong> We may disclose your information if required by law, such as in response to a subpoena, court order, or other legal processes.</li>
        </ul>

        <h3>4. Data Security</h3>
        <p>
          We take reasonable measures to protect your personal information from unauthorized access, disclosure, or misuse. However, no method of transmission over the internet or method of electronic storage is 100% secure. While we strive to protect your personal information, we cannot guarantee its absolute security.
        </p>

        <h3>5. Third-Party Services</h3>
        <p>
          Our service may contain links to third-party websites or services, such as Discord and Epic Games. Please be aware that we are not responsible for the privacy practices of these third-party services. We encourage you to review their privacy policies before linking your accounts or using their services.
        </p>

        <h3>6. Children's Privacy</h3>
        <p>
          Our services are not directed toward individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we discover that a child under 13 has provided us with personal information, we will take steps to delete such information from our systems.
        </p>

        <h3>7. Your Rights</h3>
        <p>
          Depending on your location, you may have certain rights regarding your personal information. These rights may include:
        </p>
        <ul>
          <li><strong>Access:</strong> You have the right to request access to the personal information we hold about you.</li>
          <li><strong>Correction:</strong> You have the right to request that we correct any inaccuracies in your personal information.</li>
          <li><strong>Deletion:</strong> You have the right to request the deletion of your personal information, subject to certain legal obligations.</li>
        </ul>

        <h3>8. Data Retention</h3>
        <p>
          We will retain your personal information only for as long as necessary to fulfill the purposes for which we collected it, including for the purposes of satisfying any legal, accounting, or reporting requirements.
        </p>

        <h3>9. Changes to This Privacy Policy</h3>
        <p>
          We may update this Privacy Policy from time to time. When we do, we will revise the "Last Updated" date at the bottom of this page. Your continued use of our services after any changes to this Privacy Policy constitutes your acceptance of the updated terms.
        </p>

        <h3>10. Contact Us</h3>
        <p>
          If you have any questions about this Privacy Policy or our privacy practices, please contact us at admin@refactored.gg.
        </p>

        <p><strong>Last Updated:</strong> October 1, 2024</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
