import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from "react-router-dom";
import { NavRoutes } from "../navroutes";

//My imports
import Dashboard from "./Dashboard";
import Settings from "./Settings";
import Admin from "./Admin";
import VerifyEmail from "./VerifyEmail";
import MemberList from "./MemberList";
import Webhooks from "./Webhooks";
import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";

// Import all the components
import Presentation from "./Presentation";
import Signin from "./Signin";
import Signup from "./Signup";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import NotFoundPage from "./NotFound";
import ServerError from "./ServerError";

import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

const RouteWithLoader = ({ element, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Preloader show={!loaded} />
      {loaded && element}
    </>
  );
};

const RouteWithSidebar = ({ element, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  const [showSettings, setShowSettings] = useState(() => {
    return localStorage.getItem('settingsVisible') !== 'false';
  });

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  };

  return (
    <>
      <Preloader show={!loaded} />
      {loaded && (
        <>
          <Sidebar />
          <main className="content">
            <Navbar />
            {element}
            <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
          </main>
        </>
      )}
    </>
  );
};

export default function AppRoutes() {


  return (
    <Routes>
      <Route path={NavRoutes.Settings.path} element={<RouteWithSidebar element={<Settings />} />} />
      <Route path={NavRoutes.Dashboard.path} element={<RouteWithSidebar element={<Dashboard />} />} />
      <Route path={NavRoutes.Admin.path} element={<RouteWithSidebar element={<Admin />} />} />
      <Route path={NavRoutes.VerifyEmail.path} element={<RouteWithSidebar element={<VerifyEmail />} />} />
      <Route path={NavRoutes.MemberList.path} element={<RouteWithSidebar element={<MemberList />} />} />
      <Route path={NavRoutes.Presentation.path} element={<RouteWithLoader element={<Presentation />} />} />
      <Route path={NavRoutes.Signin.path} element={<RouteWithLoader element={<Signin />} />} />
      <Route path={NavRoutes.Signup.path} element={<RouteWithLoader element={<Signup />} />} />
      <Route path={NavRoutes.ForgotPassword.path} element={<RouteWithLoader element={<ForgotPassword />} />} />
      <Route path={NavRoutes.ResetPassword.path} element={<RouteWithLoader element={<ResetPassword />} />} />
      <Route path={NavRoutes.NotFound.path} element={<RouteWithLoader element={<NotFoundPage />} />} />
      <Route path={NavRoutes.ServerError.path} element={<RouteWithLoader element={<ServerError />} />} />
      <Route path={NavRoutes.Webhooks.path} element={<RouteWithLoader element={<Webhooks />} />} />
      <Route path={NavRoutes.TermsOfService.path} element={<RouteWithSidebar element={<TermsOfService />} />} />
      <Route path={NavRoutes.PrivacyPolicy.path} element={<RouteWithSidebar element={<PrivacyPolicy />} />} />
      <Route path="*" element={<Navigate to={NavRoutes.NotFound.path} />} />
    </Routes>
  );
}
