import React, { useState, useEffect } from 'react';
import { Table, Form, Button, Row, Col, Container } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DataTables = () => {
  const [mostPopularMints, setMostPopularMints] = useState([]);
  const [highestVolumeCoins, setHighestVolumeCoins] = useState([]);
  const [highestTxCoins, setHighestTxCoins] = useState([]);

  useEffect(() => {
    const fetchMostPopularMints = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/most-popular-mints`);
        setMostPopularMints(response.data);
      } catch (error) {
        console.error('Error fetching most popular mints:', error);
      }
    };

    const fetchHighestVolumeCoins = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/highest-volume-coins`);
        setHighestVolumeCoins(response.data);
      } catch (error) {
        console.error('Error fetching highest volume coins:', error);
      }
    };

    const fetchHighestTxCoins = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/highest-tx-coins`);
        setHighestTxCoins(response.data);
      } catch (error) {
        console.error('Error fetching highest transaction coins:', error);
      }
    };

    fetchMostPopularMints();
    fetchHighestVolumeCoins();
    fetchHighestTxCoins();
  }, []);

  const renderTable = (data, columns, linkMint = false) => (
    <Table striped bordered hover>
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index}>{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            {Object.keys(item).map((key, idx) => (
              <td key={idx}>
                {key === 'mint' && linkMint ? (
                  <a href={`https://pump.fun/coin/${item[key]}`} target="_blank" rel="noopener noreferrer">
                    {item[key]}
                  </a>
                ) : (
                  item[key]
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );

  return (
    <Container>
      <Row>
        <Col>
          <h3>Most Popular Mints (Top 10)</h3>
          {renderTable(mostPopularMints, ['Mint', 'Transaction Count', 'Total SOL'], true)}
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>Highest Volume Coins (Top 100)</h3>
          {renderTable(highestVolumeCoins, ['Mint', 'Total SOL'], true)}
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>Highest Transaction Coins (Top 100)</h3>
          {renderTable(highestTxCoins, ['Mint', 'Transaction Count'], true)}
        </Col>
      </Row>
    </Container>
  );
};


export const Dashboard = () => {
  const [filters, setFilters] = useState({
    mint: '',
    solAmountMin: '',
    solAmountMax: '',
    tokenAmountMin: '',
    tokenAmountMax: '',
    isBuy: '',
    user: '',
    startDate: '',
    endDate: ''
  });
  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'SOL Amount Over Time',
        data: [],
        fill: false,
        borderColor: 'blue'
      }
    ]
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/pumpfun-data`, { params: filters });
      setData(response.data);
      prepareChartData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const prepareChartData = (data) => {
    const timestamps = data.map(item => new Date(item.timestamp * 1000));
    const solAmounts = data.map(item => parseFloat(item.solAmount));
    setChartData({
      labels: timestamps,
      datasets: [
        {
          label: 'SOL Amount Over Time',
          data: solAmounts,
          fill: false,
          borderColor: 'blue'
        }
      ]
    });
  };

  return (
    <>
    <h1>Dashboard</h1>
    <DataTables />
    <Container>      
      <Row>
        <Col md={4}>
          <Form>
            <Form.Group>
              <Form.Label>Mint</Form.Label>
              <Form.Control name="mint" onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Min SOL Amount</Form.Label>
              <Form.Control name="solAmountMin" type="number" onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Max SOL Amount</Form.Label>
              <Form.Control name="solAmountMax" type="number" onChange={handleChange} />
            </Form.Group>
            {/* Add additional filter fields here */}
            <Button variant="primary" onClick={fetchData}>Search</Button>
          </Form>
        </Col>
      </Row>
    </Container>
    <Container>
      <Row>
        <Col md={8}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Timestamp</th>
                <th>Mint</th>
                <th>SOL Amount</th>
                <th>Token Amount</th>
                <th>Is Buy</th>
                <th>User</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  <td>
                    <a href={`https://solscan.io/tx/${row.txn_sig}`} target="_blank" rel="noopener noreferrer">
                      {new Date(row.timestamp * 1000).toLocaleString()}
                    </a>
                  </td>
                  <td>{row.mint}</td>
                  <td>{row.solAmount}</td>
                  <td>{row.tokenAmount}</td>
                  <td>{row.isBuy}</td>
                  <td>{row.user}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
    <Container>
      <Row>
        <Col>
          <h2>Trading Speed Over Time</h2>
          {chartData && chartData.labels.length > 0 ? (
            <Line data={chartData} />
          ) : (
            <p>Loading chart data...</p>
          )}
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default Dashboard;
