import React, { useState, useEffect } from 'react';
import { Button, Card, Form, Row, Col } from '@themesberg/react-bootstrap'; // Use Row from react-bootstrap

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setSubmitted(true);
        setError('');
      } else {
        const result = await response.json();
        setError(result.message || 'Error subscribing to the newsletter');
      }
    } catch (error) {
      setError('An error occurred while subscribing. Please try again later.');
    }
  };

  return (
    <Card style={{ backgroundColor: 'transparent', border: 'none' }} >
      <Card.Body>
        <h5 className="mb-3">Subscribe</h5>
        {!submitted ? (
          <Form onSubmit={handleSubmit}>
            <Row> {/* Changed Form.Row to Row */}
              <Col xs={12}>
                <Form.Control
                  type="email"
                  placeholder="example@company.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Col>
              <Col xs={12} className="mt-2">
                <Button type="submit" className="btn-block" variant="secondary">
                  Subscribe
                </Button>
              </Col>
            </Row> {/* Use Row for layout */}
          </Form>
        ) : (
          <p className="text-success">Thank you for subscribing!</p>
        )}
        {error && <p className="text-danger mt-2">{error}</p>}
      </Card.Body>
    </Card>
  );
};
export default Newsletter;
