import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from '@themesberg/react-bootstrap';
import { useAuth } from "../components/AuthContext";
import { ToastContainer, toast } from 'react-toastify';
import { Tabs, Tab, Button, FormControl, FormCheck } from '@themesberg/react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';

import { Card, Form } from '@themesberg/react-bootstrap';

export const GeneralInfoForm = () => {
  const { profileData, token, fetchProfileData } = useAuth(); // Get the token and profileData from the AuthContext
  const [isSubscribed, setIsSubscribed] = useState(profileData.isSubscribed); // Newsletter subscription state

  useEffect(() => {
    if (profileData) {
      setIsSubscribed(profileData.isSubscribed); // Set true/false based on isSubscribed value
    }
  }, [profileData]);

  // Function to handle toggle switch
  const handleToggleNewsletter = async () => {
    try {
      const newSubscriptionStatus = !isSubscribed;
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth-subscribe`, {
        method: newSubscriptionStatus ? 'POST' : 'DELETE', // POST to subscribe, DELETE to unsubscribe
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: profileData.email }),
      });
      const responseData = await response.json(); // Parse response body

      if (response.ok) {
        setIsSubscribed(newSubscriptionStatus); // Update local state based on response
        await fetchProfileData(); // Fetch updated profile data
        toast.success(responseData.message); // Display success toast
      } else {
        toast.error(responseData.message); // Display error toast
        console.error('Failed to update subscription status');
      }
    } catch (error) {
      console.error('Error updating newsletter subscription:', error);
    }
  };

  return (
    <>
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Profile information</h5>
        <Form>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Username:</Form.Label>
                <Form.Label className="ms-2">{profileData.username} ({profileData.id})</Form.Label>
              </Form.Group>
            </Col>

            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Email:</Form.Label>
                <Form.Label className="ms-2">{profileData.email}</Form.Label>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Discord Username:</Form.Label>
                <Form.Label className="ms-2">{profileData.discordUsername || 'N/A'}</Form.Label>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Role:</Form.Label>
                <Form.Label className="ms-2">{profileData.role}</Form.Label>
              </Form.Group>
            </Col>

          </Row>
          <Row>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>Newsletter Subscription:</Form.Label>
                    <FormCheck
                      type="switch"
                      id="newsletter-switch"
                      label={profileData.isSubscribed === 1 ? 'Subscribed' : 'Not Subscribed'} // Explicit check for 1
                      checked={profileData.isSubscribed === 1} // Ensure the switch is checked only if isSubscribed is 1
                      onChange={handleToggleNewsletter}
                    />
                </Form.Group>
              </Col>
            </Row>
        </Form>
      </Card.Body>
    </Card>
  </>
  );
};

function ChangePasswordForm() {
  const { token } = useAuth();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      toast.error('New passwords do not match');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/change-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          currentPassword,
          newPassword,
        }),
      });

      if (!response.ok) throw new Error('Failed to change password');

      toast.success('Password changed successfully');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (err) {
      toast.error('Failed to change password');
    }
  };

  return (
    <div className="mt-4">
      <h5>Change Password</h5>
      <FormControl
        type="password"
        placeholder="Current Password"
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
        className="mb-3"
      />
      <FormControl
        type="password"
        placeholder="New Password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        className="mb-3"
      />
      <FormControl
        type="password"
        placeholder="Confirm New Password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        className="mb-3"
      />
      <Button variant="primary" onClick={handleChangePassword}>
        Change Password
      </Button>
    </div>
  );
}

const Settings = (props) => {
  const { token, isAuthenticated, profileData } = useAuth(); // Get the token and profileData from the AuthContext
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/', { replace: true });
    }
  }, [isAuthenticated, navigate]);

  if (!isAuthenticated) {
    return null;
  }

  console.log("token: ", token);
  console.log("profileData: ", profileData);
  return (
    <>
      <ToastContainer />
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-xl-0">
          <h4>Settings</h4>
          <p className="mb-0">Manage your account settings and team information.</p>
        </div>
      </div>

      <Row>
        <Col xs={12} xl={12}>
          <Tabs defaultActiveKey="generalInfo" id="management-tabs">
            <Tab eventKey="generalInfo" title="General Information">
              <GeneralInfoForm />
            </Tab>
            <Tab eventKey="changePassword" title="Change Password">
              <ChangePasswordForm />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default Settings;