import React, { useEffect, useState } from 'react';

const NewFollowerDiscordNotifier = ({ webhookUrl, followerName }) => {
  const postToDiscord = async (followerName) => {
    const message = {
      content: `🎉 New Follower: **${followerName}** just followed! Welcome!`,
    };

    try {
      const response = await fetch(webhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(message),
      });

      if (response.ok) {
        console.log('Message sent to Discord');
      } else {
        console.error('Failed to send message to Discord');
      }
    } catch (error) {
      console.error('Error posting to Discord:', error);
    }
  };

  // Send the follower name to Discord when component mounts
  useEffect(() => {
    if (followerName) {
      postToDiscord(followerName);
    }
  }, [followerName]);

  return (
    <div>
      <h3>{followerName ? `Thanks for following, ${followerName}!` : ''}</h3>
    </div>
  );
};

function Webhooks() {
  const [followerName, setFollowerName] = useState('');

  useEffect(() => {
    // Extract the username from the URL hash parameters
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.substring(hash.indexOf('?'))); // Extract the query part after the '?'
    const username = params.get('username');
    setFollowerName(username || ''); // Set the username or default to an empty string

    console.log('New follower:', username);
    console.log('Follower name:', followerName);
  }, []);

  // Replace with your actual Discord webhook URL
  const discordWebhookUrl = 'https://discord.com/api/webhooks/1288950964283375647/AsA0k6nLWb8Jpex7x_Nzr9Yt7GrycTj5LgPnErOg5NTdf5M4kJooPjXkj2_0w6wpmH1Z';

  return (
    <div className="Webhooks">
      <NewFollowerDiscordNotifier webhookUrl={discordWebhookUrl} followerName={followerName} />
    </div>
  );
}

export default Webhooks;
