import React, { useState, useEffect } from 'react';
import { useAuth } from "../components/AuthContext";
import { useNavigate } from "react-router-dom";
import { Form, Button, Col, Tab, Tabs, ProgressBar } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';

const EmailSender = ({ token }) => {
  const [subject, setSubject] = useState("");
  const [htmlContent, setHtmlContent] = useState(""); // For the HTML content
  const [previewHtml, setPreviewHtml] = useState(""); // For rendering the HTML preview
  const [templates, setTemplates] = useState([]); // To store the list of templates
  const [selectedTemplate, setSelectedTemplate] = useState(""); // Currently selected template
  const [templateName, setTemplateName] = useState(""); // Name for saving a new template
  const [isSending, setIsSending] = useState(false); // For send button state

  // Load available templates on component mount
  useEffect(() => {
    const loadTemplates = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/templates`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();
        setTemplates(data.templates); // Assuming the API returns a list of templates
      } catch (error) {
        console.error("Error loading templates:", error);
      }
    };

    loadTemplates();
  }, [token]);

  // Handle sending email
  const handleSendEmail = async (e) => {
    e.preventDefault();
    setIsSending(true);

    const emailData = {
      subject,
      htmlContent,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/send_emails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(emailData),
      });

      if (response.ok) {
        toast.success("Emails sent successfully!");
      } else {
        toast.error("Failed to send emails.");
      }
    } catch (error) {
      toast.error("Error sending emails.");
      console.error("Error:", error);
    } finally {
      setIsSending(false);
    }
  };

  // Live preview of the HTML content
  const handlePreview = (e) => {
    setHtmlContent(e.target.value);
    setPreviewHtml(e.target.value); // Update the preview as user types
  };

  // Load the selected template content
  const handleTemplateChange = async (e) => {
    const templateId = e.target.value;
    setSelectedTemplate(templateId);

    if (templateId) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/templates/${templateId}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();
        setHtmlContent(data.htmlContent); // Load the HTML content of the template
        setPreviewHtml(data.htmlContent); // Render the template in the preview
        setSubject(data.subject); // Load the subject of the template
      } catch (error) {
        console.error("Error loading template content:", error);
      }
    }
  };

  // Handle saving a new template
  const handleSaveTemplate = async () => {
    if (!templateName || !htmlContent) {
      toast.error("Please provide a template name and HTML content.");
      return;
    }

    const templateData = {
      name: templateName,
      subject,
      htmlContent,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/templates`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(templateData),
      });

      if (response.ok) {
        const data = await response.json();
        setTemplates([...templates, data.template]); // Add the new template to the list
        toast.success("Template saved successfully!");
        setTemplateName(""); // Clear the input field for template name
      } else {
        toast.error("Failed to save the template.");
      }
    } catch (error) {
      console.error("Error saving template:", error);
      toast.error("Error saving the template.");
    }
  };

  return (
    <div>
      <h3>Send Mass Emails</h3>

      <Form onSubmit={handleSendEmail}>
        <Form.Group controlId="templateSelect">
          <Form.Label>Select Template</Form.Label>
          <Form.Control as="select" value={selectedTemplate} onChange={handleTemplateChange}>
            <option value="">-- Select Template --</option>
            {templates.map((template) => (
              <option key={template.id} value={template.id}>
                {template.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="emailSubject" className="mt-3">
          <Form.Label>Email Subject</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter email subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="emailContent" className="mt-3">
          <Form.Label>Email HTML Content</Form.Label>
          <Form.Control
            as="textarea"
            rows={10}
            value={htmlContent}
            onChange={handlePreview}
            placeholder="Enter HTML content"
            required
          />
        </Form.Group>

        <Button
          variant="primary"
          type="submit"
          disabled={isSending}
          className="mt-3"
        >
          {isSending ? "Sending..." : "Send Emails"}
        </Button>
      </Form>

      <h4 className="mt-5">Preview</h4>
      <div
        className="preview-container mt-3 p-3 border rounded"
        dangerouslySetInnerHTML={{ __html: previewHtml }}
        style={{ backgroundColor: "#f8f9fa" }}
      ></div>

      <h3 className="mt-5">Save as Template</h3>
      <Form.Group controlId="templateName" className="mt-3">
        <Form.Label>Template Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter template name"
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
          required
        />
      </Form.Group>
      <Button
        variant="secondary"
        onClick={handleSaveTemplate}
        className="mt-3"
      >
        Save Template
      </Button>
    </div>
  );
};

const SendNotificationFormTwo = ({ token }) => {
  const [formData, setFormData] = useState({
    target: 'user',  // Default to user
    userIds: '',
    tournamentId: '',
    subject: '',
    message: '',
    messageType: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let identifiers;
    switch (formData.target) {
      case 'user':
        identifiers = formData.userIds.split(',').map(id => id.trim()).filter(Boolean);
        break;
      case 'tournament':
        identifiers = formData.tournamentId.trim();
        break;
      case 'all':
        identifiers = []; // No need for specific IDs when sending to all
        break;
      default:
        return;
    }

    const data = {
      target: formData.target,
      identifiers,  // Can be an array or single value
      subject: formData.subject,
      message: formData.message,
      messageType: formData.messageType,
    };

    fetch(`${process.env.REACT_APP_API_URL}/send_notifications_two`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        toast.success('Notification sent successfully!');
      })
      .catch((error) => {
        toast.error('Failed to send notification.');
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group as={Col}>
        <Form.Label>Send Notification To:</Form.Label>
        <Form.Check
          type="radio"
          label="User ID(s)"
          name="target"
          value="user"
          checked={formData.target === 'user'}
          onChange={handleChange}
        />
        <Form.Check
          type="radio"
          label="Tournament ID"
          name="target"
          value="tournament"
          checked={formData.target === 'tournament'}
          onChange={handleChange}
        />
        <Form.Check
          type="radio"
          label="All Users"
          name="target"
          value="all"
          checked={formData.target === 'all'}
          onChange={handleChange}
        />
      </Form.Group>

      {formData.target === 'user' && (
        <Form.Group as={Col} controlId="formUserIds">
          <Form.Label>User ID(s)</Form.Label>
          <Form.Control
            type="text"
            name="userIds"
            value={formData.userIds}
            onChange={handleChange}
            placeholder="Enter User ID(s), comma separated"
          />
        </Form.Group>
      )}

      {formData.target === 'tournament' && (
        <Form.Group as={Col} controlId="formTournamentId">
          <Form.Label>Tournament ID</Form.Label>
          <Form.Control
            type="text"
            name="tournamentId"
            value={formData.tournamentId}
            onChange={handleChange}
            placeholder="Enter Tournament ID"
          />
        </Form.Group>
      )}

      <Form.Group as={Col} controlId="formSubject">
        <Form.Label>Subject</Form.Label>
        <Form.Control
          type="text"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          placeholder="Enter subject"
        />
      </Form.Group>

      <Form.Group as={Col} controlId="formMessage">
        <Form.Label>Message</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Enter your message here"
        />
      </Form.Group>

      <Form.Group as={Col} controlId="formMessageType">
        <Form.Label>Message Type</Form.Label>
        <Form.Control as="select" name="messageType" value={formData.messageType} onChange={handleChange}>
          <option value="">Select a message type</option>
          <option value="info">Info</option>
          <option value="warning">Warning</option>
          <option value="alert">Alert</option>
          <option value="success">Success</option>
        </Form.Control>
      </Form.Group>

      <Button variant="primary" type="submit">Send Notification</Button>
    </Form>
  );
};
/*
const SendNotificationForm = ({ profileData, token }) => {
  const [formData, setFormData] = useState({
    target: 'user',
    userIds: '',
    tournamentId: '',
    subject: '',
    message: '',
    messageType: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let identifiers;
    switch (formData.target) {
      case 'user':
        identifiers = formData.userIds.split(',').map(id => id.trim()).filter(Boolean);
        break;
      case 'tournament':
        identifiers = formData.tournamentId.trim();
        break;
      default:
        return;
    }

    const data = {
      target: formData.target,
      identifiers,
      subject: formData.subject,
      message: formData.message,
      messageType: formData.messageType,
    };

    fetch(`${process.env.REACT_APP_API_URL}/send_notifications`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        toast.success('Notification sent successfully!');
      })
      .catch((error) => {
        toast.error('Failed to send notification.');
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group as={Col}>
        <Form.Label>Send Notification To:</Form.Label>
        <Form.Check
          type="radio"
          label="User ID(s)"
          name="target"
          value="user"
          checked={formData.target === 'user'}
          onChange={handleChange}
        />
        <Form.Check
          type="radio"
          label="Tournament ID"
          name="target"
          value="tournament"
          checked={formData.target === 'tournament'}
          onChange={handleChange}
        />
      </Form.Group>

      {formData.target === 'user' && (
        <Form.Group as={Col} controlId="formUserIds">
          <Form.Label>User ID(s)</Form.Label>
          <Form.Control
            type="text"
            name="userIds"
            value={formData.userIds}
            onChange={handleChange}
            placeholder="Enter User ID(s), comma separated"
          />
        </Form.Group>
      )}

      {formData.target === 'tournament' && (
        <Form.Group as={Col} controlId="formTournamentId">
          <Form.Label>Tournament ID</Form.Label>
          <Form.Control
            type="text"
            name="tournamentId"
            value={formData.tournamentId}
            onChange={handleChange}
            placeholder="Enter Tournament ID"
          />
        </Form.Group>
      )}

      <Form.Group as={Col} controlId="formSubject">
        <Form.Label>Subject</Form.Label>
        <Form.Control
          type="text"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          placeholder="Enter subject"
        />
      </Form.Group>

      <Form.Group as={Col} controlId="formMessage">
        <Form.Label>Message</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Enter your message here"
        />
      </Form.Group>

      <Form.Group as={Col} controlId="formMessageType">
        <Form.Label>Message Type</Form.Label>
        <Form.Control as="select" name="messageType" value={formData.messageType} onChange={handleChange}>
          <option value="">Select a message type</option>
          <option value="info">Info</option>
          <option value="warning">Warning</option>
          <option value="alert">Alert</option>
          <option value="success">Success</option>
        </Form.Control>
      </Form.Group>

      <Button variant="primary" type="submit">Send Notification</Button>
    </Form>
  );
};
*/
const ReplayUploadPage = ({profileData, token}) => {
  const [tournamentId, setTournamentId] = useState(''); // For tournament ID input
  const [uploadProgress, setUploadProgress] = useState(0); // Progress bar state
  const [isUploading, setIsUploading] = useState(false); // Uploading state
  const [games, setGames] = useState([{ file: null, gameNumber: 1 }]); // Game inputs

  // Handle adding a new game input field
  const addGameInput = () => {
    setGames([...games, { file: null, gameNumber: games.length + 1 }]);
  };

  // Handle removing a game input field
  const removeGameInput = (index) => {
    setGames(games.filter((_, i) => i !== index));
  };

  // Handle file change for a specific game input
  const handleFileChange = (e, index) => {
    const newGames = [...games];
    newGames[index].file = e.target.files[0]; // Only accept a single file per game
    setGames(newGames);
  };

  // Handle game number change
  const handleGameNumberChange = (e, index) => {
    const newGames = [...games];
    newGames[index].gameNumber = e.target.value;
    setGames(newGames);
  };

  // Handle input change for tournament ID
  const handleTournamentIdChange = (e) => {
    setTournamentId(e.target.value);
  };

  // Handle file upload
  const handleUpload = async () => {
    if (!tournamentId) {
      alert('Please enter a tournament ID.');
      return;
    }

    const formData = new FormData();
    formData.append('tournamentId', tournamentId); // Append the tournament ID

    games.forEach((game, index) => {
      if (game.file && game.gameNumber) {
        formData.append(`replay_${index}`, game.file); // Append each game file
        formData.append(`gameNumber_${index}`, game.gameNumber); // Append each game number
      }
    });

    setIsUploading(true); // Set uploading state to true

    try {
      // Upload files to the server
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/upload-replays`, 
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted); // Update progress bar as files upload
          },
        }
      );

      if (response.data.success) {
        alert('Replays uploaded successfully.');
      } else {
        alert('Error uploading replays: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error uploading replays:', error);
      alert('Error uploading replays.');
    }

    setIsUploading(false); // Reset uploading state after completion
  };

  return (
      <div>
        <h3>Upload Replay Files</h3>

        {/* Tournament ID input */}
        <Form.Group>
          <Form.Label>Tournament ID</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Enter tournament ID" 
            value={tournamentId} 
            onChange={handleTournamentIdChange} // Handle tournament ID input change
          />
        </Form.Group>

        {/* Dynamic game inputs */}
        {games.map((game, index) => (
          <div key={index} style={{ marginBottom: '15px' }}>
            <Form.Group>
              <Form.Label>Game {index + 1} - Replay File</Form.Label>
              <Form.Control 
                type="file" 
                onChange={(e) => handleFileChange(e, index)}
                accept=".replay" // Only accept .replay files
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Game Number</Form.Label>
              <Form.Control 
                type="number" 
                value={game.gameNumber}
                onChange={(e) => handleGameNumberChange(e, index)}
              />
            </Form.Group>

            <Button 
              variant="danger" 
              onClick={() => removeGameInput(index)} 
              disabled={games.length === 1} // Disable removing if only one game
            >
              Remove Game
            </Button>
          </div>
        ))}

        {/* Add another game button */}
        <Button variant="secondary" onClick={addGameInput}>
          Add Another Game
        </Button>

        {/* Upload button */}
        <Button 
          variant="primary" 
          onClick={handleUpload} 
          disabled={isUploading || games.length === 0 || games.some(game => !game.file)} // Disable if uploading or no files selected
        >
          {isUploading ? 'Uploading...' : 'Upload Replays'} {/* Button text changes during upload */}
        </Button>

        {/* Upload progress bar */}
        {isUploading && <ProgressBar animated now={uploadProgress} label={`${uploadProgress}%`} />}
      </div>
  );
};

const OrganizeLobby = ({ profileData, token }) => {
  const [isAuth, setIsAuth] = useState(false);

  // Function to get the first Friday of the next month at 9 PM
  const getFirstFridayNextMonth = () => {
    const now = new Date();
    const nextMonth = now.getMonth() + 1; // Move to next month
    const firstDayNextMonth = new Date(now.getFullYear(), nextMonth, 1); // First day of next month
  
    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 5 = Friday)
    let dayOfWeek = firstDayNextMonth.getDay();
    
    // Calculate the date of the first Friday (if the first day is Friday, it will be 0)
    const daysUntilFriday = (5 - dayOfWeek + 7) % 7;
  
    // Set the date to the first Friday
    const firstFriday = new Date(firstDayNextMonth);
    firstFriday.setDate(firstDayNextMonth.getDate() + daysUntilFriday);
  
    // Set the time to 9 PM (21:00)
    firstFriday.setHours(21, 0, 0, 0);
  
    // Format the date as 'yyyy-MM-ddTHH:mm' in the local time zone
    const year = firstFriday.getFullYear();
    const month = String(firstFriday.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(firstFriday.getDate()).padStart(2, '0');
    const hours = String(firstFriday.getHours()).padStart(2, '0');
    const minutes = String(firstFriday.getMinutes()).padStart(2, '0');
  
    // Combine into the datetime-local format 'yyyy-MM-ddTHH:mm'
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
  
    return formattedDate;
  };
  

  // Form state with default start time as the first Friday of the next month at 9 PM
  const [formData, setFormData] = useState({
    title: 'Fortnite',
    tier: 'Basic',
    requiredTickets: '0',
    type: 'Solo Tournament',
    tournamentType: 'Solo Zero Build',
    teamSize: '1',
    maxTotalTeams: '99',
    startTime: getFirstFridayNextMonth(), // Default start time
    prizePool: '1000 V-Bucks',
    region: 'NA-EAST', 
    platform: 'ALL',
    rules: '',
  });

  // Handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'startTime') {
      // Keep the form value in `datetime-local` format but convert it to epoch later in handleSubmit
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Convert the `startTime` to an epoch timestamp before submitting the form
    const epochStartTime = Math.floor(new Date(formData.startTime).getTime() / 1000);

    const tournamentData = {
      ...formData,
      startTime: epochStartTime, // Replace startTime with epoch format
    };

    console.log('Form Data with epoch startTime:', tournamentData);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/create_tournament`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(tournamentData),
      });

      const data = await response.json();

      if (response.ok) {
        alert('Tournament created successfully!');
        console.log(data);
      } else {
        throw new Error(data.message || 'Failed to create the tournament.');
      }
    } catch (error) {
      console.error('Error creating tournament:', error);
      alert(error.message);
    }
  };

  // Check for user roles to authorize organizing tournaments
  useEffect(() => {
    const checkRoles = async () => {
      if (profileData.role === 'admin' || profileData.role === 'organizer') {
        setIsAuth(true);
      } else {
        setIsAuth(false);
      }
    };
    checkRoles();
  }, [token, profileData.role]);

  return (
    <div className="content-layout">
      <h1 className='ailerons brand-color-blue content__title'>Organize Tournament</h1>
      {isAuth ? (
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Title</Form.Label>
            <Form.Select name="title" value={formData.title} onChange={handleChange}>
              <option value="">Select Game</option>
              <option value="Call of Duty">Call of Duty</option>
              <option value="Fortnite">Fortnite</option>
              <option value="Apex Legends">Apex Legends</option>
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label>Tier</Form.Label>
            <Form.Select name="tier" value={formData.tier} onChange={handleChange}>
              <option value="">Select Tier</option>
              <option value="Basic">Free</option>
              <option value="Copper">Copper</option>
              <option value="Bronze">Bronze</option>
              <option value="Silver">Silver</option>
              <option value="Gold">Gold</option>
              <option value="Diamond">Diamond</option>
              <option value="Platinum">Platinum</option>
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label>Required Tickets</Form.Label>
            <Form.Control name="requiredTickets" value={formData.requiredTickets} onChange={handleChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Type</Form.Label>
            <Form.Control name="type" value={formData.type} onChange={handleChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Region</Form.Label>
            <Form.Control name="region" value={formData.region} onChange={handleChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Platform</Form.Label>
            <Form.Control name="platform" value={formData.platform} onChange={handleChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Tournament Type</Form.Label>
            <Form.Control name="tournamentType" value={formData.tournamentType} onChange={handleChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Team Size</Form.Label>
            <Form.Control name="teamSize" value={formData.teamSize} onChange={handleChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Max Total Teams</Form.Label>
            <Form.Control type="number" name="maxTotalTeams" value={formData.maxTotalTeams} onChange={handleChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Start Time</Form.Label>
            <Form.Control type="datetime-local" name="startTime" value={formData.startTime} onChange={handleChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Prize Pool</Form.Label>
            <Form.Control name="prizePool" value={formData.prizePool} onChange={handleChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Rules</Form.Label>
            <Form.Control 
              as="textarea" 
              rows={5}  
              name="rules" 
              value={formData.rules} 
              onChange={handleChange} 
            />
          </Form.Group>

          <Button variant="primary" type="submit">Start Tournament</Button>
        </Form>
      ) : (
        <h2 className="text-white">You are not authorized to organize a tournament.</h2>
      )}
    </div>
  );
};


const UpdateTournamentRules = ({ token }) => {
  const [tournamentId, setTournamentId] = useState(''); // Store selected tournament ID
  const [rules, setRules] = useState(''); // Store the rules HTML content
  const [tournaments, setTournaments] = useState([]); // Store list of active tournaments
  const [isLoading, setIsLoading] = useState(false);

  // Fetch active tournaments on component mount
  useEffect(() => {
    const fetchActiveTournaments = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/get_active_tournaments`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setTournaments(data); // Set the active tournaments list
      } catch (error) {
        console.error('Error fetching tournaments:', error);
        toast.error('Error loading active tournaments.');
      }
    };

    fetchActiveTournaments();
  }, [token]);

  // Fetch the current rules of the selected tournament
  const fetchTournamentRules = async (id) => {
    if (!id) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/tournaments/${id}/rules`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      if (response.ok) {
        setRules(data.rules); // Load current rules into the state
      } else {
        toast.error('Failed to load rules.');
      }
    } catch (error) {
      console.error('Error fetching rules:', error);
      toast.error('Error fetching rules.');
    }
  };

  // Handle form submission to update the rules
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/tournaments/${tournamentId}/rules`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ rules }),
      });

      if (response.ok) {
        toast.success('Tournament rules updated successfully.');
      } else {
        toast.error('Failed to update rules.');
      }
    } catch (error) {
      console.error('Error updating rules:', error);
      toast.error('Error updating rules.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h3>Update Tournament Rules</h3>
      <Form onSubmit={handleSubmit}>
        {/* Dropdown to select an active tournament */}
        <Form.Group>
          <Form.Label>Select Tournament</Form.Label>
          <Form.Control
            as="select"
            value={tournamentId}
            onChange={(e) => {
              setTournamentId(e.target.value);
              fetchTournamentRules(e.target.value); // Fetch rules when tournament is selected
            }}
            required
          >
            <option value="">-- Select Tournament --</option>
            {tournaments.map((tournament) => (
              <option key={tournament.id} value={tournament.id}>
                {tournament.title}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        {/* Display current rules in a textarea */}
        <Form.Group className="mt-3">
          <Form.Label>Rules (HTML Content)</Form.Label>
          <Form.Control
            as="textarea"
            rows={10}
            value={rules}
            onChange={(e) => setRules(e.target.value)}
            placeholder="Edit the tournament rules in HTML format"
            required
          />
        </Form.Group>

        <Button type="submit" variant="primary" className="mt-3" disabled={isLoading}>
          {isLoading ? 'Updating...' : 'Update Rules'}
        </Button>
      </Form>

      {/* Display current rules in HTML view */}
      {rules && (
        <div className="mt-5">
          <h4>Current Rules Preview</h4>
          <div dangerouslySetInnerHTML={{ __html: rules }} className="border p-3" />
        </div>
      )}
    </div>
  );
};

export const Admin = () => {
  const { token, isAuthenticated, profileData } = useAuth(); // Get the token and profileData from the AuthContext
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState("notify");

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/', { replace: true });
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (profileData?.role !== "admin") {
      navigate('/', { replace: true });
    }
  }, [profileData, navigate]);

  if (!isAuthenticated || profileData?.role !== "admin") {
    return null;
  }

  return (
    <div className="content-layout">
      <h1 id="page-title" className="ailerons brand-color-blue content__title">
        Admin Page
      </h1>
      <div className="content__body">
        <Tabs defaultActiveKey="notify" activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
        {/*}
          <Tab eventKey="notify" title="Notify">
            <SendNotificationForm profileData={profileData} token={token} />
          </Tab>
        */}
          <Tab eventKey="notifyTwo" title="Notify">
            <SendNotificationFormTwo profileData={profileData} token={token} />
          </Tab>
          <Tab eventKey="UploadTournaments" title="Upload Replay">
            <ReplayUploadPage profileData={profileData} token={token} />
          </Tab>
          <Tab eventKey="CreateTournament" title="Tournament">
            <OrganizeLobby profileData={profileData} token={token} />
          </Tab>
          <Tab eventKey="sendEmail" title="Send Email">
            <EmailSender token={token} />
          </Tab>
          <Tab eventKey="updateRules" title="Update Tournament Rules">
            <UpdateTournamentRules token={token} />
          </Tab>
        </Tabs>
      </div>
      <Toaster /> {/* Optional notification toaster */}
    </div>
  );
};

export default Admin;
