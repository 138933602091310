import React, { useState, useEffect } from "react";
import { Col, Row, Card, Image, Container, Navbar } from '@themesberg/react-bootstrap';
import { useAuth } from "../components/AuthContext"; // Use the AuthContext
import { useNavigate } from "react-router-dom";
import ReactHero from "../assets/img/rf-logo.png";
import { Button } from '@themesberg/react-bootstrap';
import RegisterModal from "../components/Register";
import LoginModal from "../components/Login";
import Newsletter from "../components/Newsletter";
import { ToastContainer } from "react-toastify";

export default function HomePage() {
  const { token, handleLogin } = useAuth(); // Use the AuthContext for token and login handling
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  // Register modal state
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const handleShowRegisterModal = () => setShowRegisterModal(true);
  const handleCloseRegisterModal = () => setShowRegisterModal(false);

  // Login modal state
  const [showLoginModal, setShowLoginModal] = useState(false);
  const handleShowLoginModal = () => setShowLoginModal(true);
  const handleCloseLoginModal = () => setShowLoginModal(false);

  // If token exists, navigate to the settings page
  useEffect(() => {
    if (token) {
      navigate('/settings', { replace: true }); // Use replace to avoid adding to history stack
    }
  }, [token, navigate]);

  if (token) {
    return null; // Optionally return a loading spinner or null while redirecting
  }

  return (
    <>
    <ToastContainer />
      <Navbar variant="dark" expand="lg" bg="dark" className="navbar-transparent navbar-theme-primary sticky-top">
        <Container className="position-relative justify-content-between px-3">
          <Navbar.Brand href="/" className="me-lg-3 d-flex align-items-center">
            <Image src={ReactHero} />
            <span className="ms-2 brand-text d-none d-md-inline">Alpha Citadel</span>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <section className="section-header overflow-hidden pt-5 pt-lg-6 pb-9 pb-lg-12 bg-primary text-white" id="home">
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <div className="react-big-icon d-none d-lg-block"><span className="fab fa-react"></span></div>
              <h1 className="fw-bolder text-secondary">Alpha Citadel</h1>
              <p className="text-muted fw-light mb-5 h5">Check-in with our <a href="https://discord.gg/S8dWhXywgB">Discord</a></p>
              <div className="d-flex justify-content-center flex-column mb-6 mb-lg-5 mt-5">
                <div className="text-center">
                  <h3 className="mb-4">Login to Your Account</h3>
                  <div className="d-flex justify-content-center">
                    <div className="w-50">
                        <Button variant="secondary" size="xs" onClick={handleShowLoginModal}>
                          Login
                        </Button>
                        <LoginModal show={showLoginModal} handleClose={handleCloseLoginModal} />
                        <span className="text-muted"> or </span>
                        <Button variant="secondary" size="xs" onClick={handleShowRegisterModal} className="text-dark">
                          Register
                        </Button>
                        <RegisterModal show={showRegisterModal} handleClose={handleCloseRegisterModal} />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <footer className="footer py-6 bg-dark text-white">
        <Container>
          <Row>
            <Col md={4}>
              <Navbar.Brand href="#home" className="me-lg-3 mb-3 d-flex align-items-center">
                <Image src={ReactHero} />
                <span className="ms-2 brand-text">Alpha Citadel</span>
              </Navbar.Brand>
              <p>AI Curated Cryptocurrency Analysis</p>
            </Col>
            <Col xs={6} md={2} className="mb-5 mb-lg-0">
              <span className="h5">Socials</span>
              <ul className="links-vertical mt-2">
                <li><Card.Link target="_blank" href="https://x.com/AlphaCitadel">X</Card.Link></li>
                <li><Card.Link target="_blank" href="https://discord.gg/S8dWhXywgB">Discord</Card.Link></li>
              </ul>
            </Col>
            <Col xs={6} md={2} className="mb-5 mb-lg-0">
              <span className="h5">Other</span>
              <ul className="links-vertical mt-2">
                <li><Card.Link target="_blank" href="https://refactored.gg/#/TOS">Terms Of Service</Card.Link></li>
                <li><Card.Link target="_blank" href="https://refactored.gg/#/PrivacyPolicy">Privacy Policy</Card.Link></li>
              </ul>
            </Col>
            <Col xs={12} md={4} className="mb-5 mb-lg-0">
              <section className="subscribe-section">
                <Newsletter />
              </section>
              <p className="text-muted font-small m-0">We’ll never share your details. See our <Card.Link className="text-white" href="https://refactored.gg/#/PrivacyPolicy">Privacy Policy</Card.Link></p>
            </Col>
          </Row>
          <hr className="bg-gray my-5" />
          <Row>
            <Col className="mb-md-2">
              <Card.Link href="#" target="_blank" className="d-flex justify-content-center">
                {/* <Image src={ThemesbergLogo} height={35} className="d-block mx-auto mb-3" alt="Themesberg Logo" /> */}
              </Card.Link>
              <div className="d-flex text-center justify-content-center align-items-center" role="contentinfo">
                <p className="font-weight-normal font-small mb-0">Copyright © Alpha Citadel 2019-<span className="current-year">2021</span>. All rights reserved.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}
